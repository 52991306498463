




















































































































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import TerminosYCondicionesComponent from "@/components/Common/TerminosYCondicionesComponent.vue";

@Component({
  components: {
    TerminosYCondicionesComponent
  }
})
export default class TerminosYCondicionesView extends Vue {
  private mounted() {}
}
