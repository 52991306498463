










































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator";
import TerminosYCondicionesComponent from "@/components/Common/TerminosYCondicionesComponent.vue";

interface dataSolicitud {
  rut: string | null;
  nombre: string | null;
  razon_social: string | any;
  descripcion: string | null;
  rut_contacto: string | null;
  nombre_contacto: string | null;
  email_contacto: string | null;
  tipo_empresa: number | null;
  email: string | null;
  telefono: string | null;
  web: string | null;
  instagram: string | null;
  facebook: string | null;
  direccion: string | null;
  referencia: string | null;
  categoria: number | null;
  documento_sii: string | null;
  documento_patente: string | null;
  documento_sanitaria: string | null;
  documento_representante: string | null;
  documento_logo: string | null;
  estado: number | null;
  observaciones: string | null;
  fecha_registro: string | null;
  fecha_revision: string | null;
  codigo_correccion: string | null;
  id_lector: number | null;
  documentoSii_ext: string | null;
  documentoPatente_ext: string | null;
  documentoSanitaria_ext: string | null;
  documentoRepresentante_ext: string | null;
  documentoLogo_ext: string | null;
}

interface SelectOption {
  value: number | any;
  label: string;
}

@Component({
  components: {
    TerminosYCondicionesComponent
  }
})
export default class Formulario extends Mixins(FormValidator) {
  @Prop({ required: false })
  private data!: any;
  @Prop({ required: false })
  private rut!: any;
  private dataForm: dataSolicitud = {
    rut: "",
    nombre: "",
    razon_social: "",
    descripcion: "",
    rut_contacto: "",
    nombre_contacto: "",
    email_contacto: "",
    tipo_empresa: null,
    email: "",
    telefono: "",
    web: "",
    instagram: "",
    facebook: "",
    direccion: "",
    referencia: "",
    categoria: null,
    documento_sii: "",
    documento_patente: "",
    documento_sanitaria: "",
    documento_representante: "",
    documento_logo: "",
    documentoSii_ext: "",
    documentoPatente_ext: "",
    documentoSanitaria_ext: "",
    documentoRepresentante_ext: "",
    documentoLogo_ext: "",
    estado: 0,
    observaciones: "",
    fecha_registro: "",
    fecha_revision: "",
    codigo_correccion: "",
    id_lector: null
  };
  private optionTipo: SelectOption[] = [
    { value: 0, label: "Online" },
    { value: 1, label: this.$t("fields.type_convenio_1").toString() }
  ];
  private optionCategoria: SelectOption[] = [];
  private optionCobertura: SelectOption[] = [
    { value: 0, label: "Nacional" },
    { value: 1, label: "Regional" },
    { value: 2, label: "Comunal" }
  ];
  private selectTipo: SelectOption = {
    value: null,
    label: this.$t("fields.convenio_tipo_select").toString()
  };
  private selectCategoria: SelectOption = {
    value: null,
    label: this.$t("fields.convenio_categoria_select").toString()
  };
  private selectCobertura: SelectOption = {
    value: null,
    label: this.$t("fields.convenio_cobertura_select").toString()
  };

  private formattedRut = "";
  private formattedRut_contacto = "";
  private direccionTemporal: any = "";
  private aclaratoriaTemporal: any = "";
  private invalidFileSii = false;
  private invalidFilePatente = false;
  private invalidFileSanitaria = false;
  private invalidFileRepresentante = false;
  private invalidFileLogo = false;
  private direccionDisable: boolean = false;
  private enviarDisable: boolean = false;
  private downloadDocumentoSii: boolean = false;
  private downloadDocumentoPatente: boolean = false;
  private downloadDocumentoSanitaria: boolean = false;
  private downloadDocumentoRepresentante: boolean = false;
  private downloadDocumentoLogo: boolean = false;

  private documentoSii: any = null;
  private documentoPatente: any = null;
  private documentoSanitaria: any = null;
  private documentoRepresentante: any = null;
  private documentoLogo: any = null;

  private bTerminos = false;
  private terminosFooter = false;
  private modelTerminos = "no";

  @Watch("formattedRut")
  private formatRut(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.dataForm.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  @Watch("formattedRut_contacto")
  private formatRutContacto(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.dataForm.rut_contacto = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut_contacto = `${rut}-${dv}`;
    } else {
      this.formattedRut_contacto = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  @Watch("selectTipo")
  private selectTipoWatch(newValue: any) {
    this.dataForm.tipo_empresa = newValue.value;
    if (newValue.value == 0) {
      this.direccionTemporal = this.dataForm.direccion;
      this.aclaratoriaTemporal = this.dataForm.referencia;
      this.dataForm.direccion = "ONLINE";
      this.dataForm.referencia = "";
      this.direccionDisable = true;
    } else {
      this.dataForm.direccion = this.direccionTemporal;
      this.dataForm.referencia = this.aclaratoriaTemporal;
      this.direccionDisable = false;
    }
  }
  @Watch("selectCategoria")
  private selectCategoriaWatch(newValue: any) {
    this.dataForm.categoria = newValue.value;
  }

  @Watch("dataForm.descripcion_corta")
  private descripcionWatch(newValue: any) {
    this.dataForm.descripcion = newValue.substring(0, 140);
  }
  private mounted() {
    this.getCategorias();
    if (this.data && this.data.nombre && this.data.nombre != "") {
      this.dataForm.nombre = this.data.nombre;
      this.dataForm.rut = this.data.rut;
      this.formattedRut = this.data.rut;
      this.dataForm.rut_contacto = this.data.rut_contacto;
      this.formattedRut_contacto = this.data.rut_contacto;
      this.dataForm.tipo_empresa = this.data.tipo_empresa;
      this.dataForm.razon_social = this.data.razon_social;
      this.dataForm.email = this.data.email;
      this.dataForm.nombre_contacto = this.data.nombre_representante;
      this.dataForm.email_contacto = this.data.email_representante;
      this.dataForm.telefono = this.data.telefono;
      this.direccionTemporal = this.data.direccion;
      this.dataForm.direccion = this.data.direccion;
      this.dataForm.referencia = this.data.referencia;
      this.aclaratoriaTemporal = this.data.referencia;
      this.dataForm.categoria = this.data.categoria;
      this.dataForm.descripcion = this.data.descripcion;
      this.dataForm.web = this.data.web;
      this.dataForm.instagram = this.data.instagram;
      this.dataForm.facebook = this.data.facebook;
      this.dataForm.estado = this.data.estado;
      this.dataForm.observaciones = this.data.observaciones;
      this.dataForm.fecha_revision = this.data.fecha_revision;
      this.dataForm.documento_sii = this.data.documento_sii;
      this.documentoSii = this.data.documento_sii;
      this.dataForm.documento_patente = this.data.documento_patente;
      this.documentoPatente = this.data.documento_patente;
      this.dataForm.documento_sanitaria = this.data.documento_sanitaria;
      this.documentoSanitaria = this.data.documento_sanitaria;
      this.dataForm.documento_representante = this.data.documento_representante;
      this.documentoRepresentante = this.data.documento_representante;
      this.dataForm.documento_logo = this.data.documento_logo;
      this.documentoLogo = this.data.documento_logo;
      this.dataForm.documentoSii_ext = this.data.documentoSii_ext;
      this.dataForm.documentoPatente_ext = this.data.documentoPatente_ext;
      this.dataForm.documentoSanitaria_ext = this.data.documentoSanitaria_ext;
      this.dataForm.documentoRepresentante_ext = this.data.documentoRepresentante_ext;
      this.dataForm.documentoLogo_ext = this.data.documentoLogo_ext;

      this.downloadDocumentoSii =
        this.dataForm.documento_sii && this.dataForm.documento_sii != ""
          ? true
          : false;
      this.downloadDocumentoPatente =
        this.dataForm.documento_patente && this.dataForm.documento_patente != ""
          ? true
          : false;
      this.downloadDocumentoSanitaria =
        this.dataForm.documento_sanitaria &&
        this.dataForm.documento_sanitaria != ""
          ? true
          : false;
      this.downloadDocumentoRepresentante =
        this.dataForm.documento_representante &&
        this.dataForm.documento_representante != ""
          ? true
          : false;
      this.downloadDocumentoLogo =
        this.dataForm.documento_logo && this.dataForm.documento_logo != ""
          ? true
          : false;

      for (let i = 0; i < this.optionTipo.length; i++) {
        const element = this.optionTipo[i];
        if (this.dataForm.tipo_empresa == element.value) {
          this.selectTipo = element;
        }
      }

      if (this.optionCategoria.length > 0) {
        for (let i = 0; i < this.optionCategoria.length; i++) {
          const element = this.optionCategoria[i];
          if (this.dataForm.categoria == element.value) {
            this.selectCategoria = element;
          }
        }
      }
    } else if (this.rut && this.rut != "" && this.validateRut(this.rut)) {
      this.formattedRut = this.rut;
    } else {
      this.$swal({
        icon: "warning",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.ERROR_FORM").toString(),
        confirmButtonText: this.$t("buttons.accept").toString(),
        didDestroy: () => {
          this.$router.go(0);
        }
      });
    }
  }
  private getCategorias() {
    this.$axios
      .get("/listado/categorias")
      .then((res: any) => {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          this.optionCategoria.push({
            value: element.id,
            label: element.nombre
          });
        }
        if (this.dataForm.categoria && this.dataForm.categoria != 0) {
          for (let i = 0; i < this.optionCategoria.length; i++) {
            const element = this.optionCategoria[i];
            if (this.dataForm.categoria == element.value) {
              this.selectCategoria = element;
            }
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  private send() {
    if (
      this.dataForm.rut == "" ||
      this.validateRut(this.dataForm.rut) == false
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.NO_RUT").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (!this.dataForm.nombre || this.dataForm.nombre.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.convenio_no_name").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (
      !this.dataForm.razon_social ||
      this.dataForm.razon_social.trim() == ""
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: "Por favor, ingrese la Razón Social",
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.dataForm.tipo_empresa === null) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.convenio_no_type").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (!this.dataForm.email || this.dataForm.email.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.convenio_no_email").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (!this.validEmail(this.dataForm.email)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.convenio_no_email_valid").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (
      this.dataForm.rut_contacto == "" ||
      this.validateRut(this.dataForm.rut_contacto) == false
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.NO_RUT_CONTACTO").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (
      !this.dataForm.nombre_contacto ||
      this.dataForm.nombre_contacto.trim() == ""
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t(
          "notifications.error.convenio_no_nombre_representante"
        ).toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (
      !this.dataForm.email_contacto ||
      this.dataForm.email_contacto.trim() == ""
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t(
          "notifications.error.convenio_no_representante_email"
        ).toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (!this.validEmail(this.dataForm.email_contacto)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t(
          "notifications.error.convenio_no_representante_email_valid"
        ).toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (!this.dataForm.telefono || this.dataForm.telefono.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.NO_PHONE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    } else {
      if (this.dataForm.telefono.length < 9) {
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.solicitud").toString(),
          text: this.$t("notifications.error.FORMAT_PHONE").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        return;
      }
    }

    if (!this.direccionDisable) {
      if (!this.dataForm.direccion || this.dataForm.direccion.trim() == "") {
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.solicitud").toString(),
          text: this.$t("notifications.error.convenio_no_address").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        return;
      }
    }

    if (this.dataForm.categoria === null) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.convenio_no_category").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (!this.dataForm.descripcion || this.dataForm.descripcion.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.convenio_no_descripcion").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (this.documentoSii == null || this.invalidFileSii) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.convenio_no_sii").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.documentoPatente == null || this.invalidFilePatente) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.convenio_no_patente").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.documentoRepresentante == null || this.invalidFileRepresentante) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t(
          "notifications.error.convenio_no_representante"
        ).toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.documentoLogo == null || this.invalidFileLogo) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.convenio_no_logo").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.documentoSanitaria != null && this.invalidFileSanitaria) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.convenio_no_sanitaria").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.modelTerminos == "no" || !this.modelTerminos) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.ERROR_TERMINOS").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    this.enviarDisable = true;
    this.$q.loading.show({
      delay: 100 // ms
    });
    const data = new FormData();

    if (typeof this.documentoSii == "string") {
      this.documentoSii = this.base64ImageToBlob(this.documentoSii);
    }
    if (typeof this.documentoPatente == "string") {
      this.documentoPatente = this.base64ImageToBlob(this.documentoPatente);
    }
    if (typeof this.documentoSanitaria == "string") {
      this.documentoSanitaria = this.base64ImageToBlob(this.documentoSanitaria);
    }
    if (typeof this.documentoRepresentante == "string") {
      this.documentoRepresentante = this.base64ImageToBlob(
        this.documentoRepresentante
      );
    }
    if (typeof this.documentoLogo == "string") {
      this.documentoLogo = this.base64ImageToBlob(this.documentoLogo);
    }
    if (this.documentoSii) {
      const ciFile = this.documentoSii as Blob;
      data.append("documento_sii", ciFile);
    } else {
      const ciFile = new File(["file"], "documento_sii") as Blob;
      data.append("documento_sii", ciFile);
    }
    if (this.documentoPatente) {
      const ciFile = this.documentoPatente as Blob;
      data.append("documento_patente", ciFile);
    } else {
      const ciFile = new File(["file"], "documento_patente") as Blob;
      data.append("documento_patente", ciFile);
    }
    if (this.documentoSanitaria) {
      const ciFile = this.documentoSanitaria as Blob;
      data.append("documento_sanitaria", ciFile);
    } else {
      data.append("documento_sanitaria", "");
    }
    if (this.documentoRepresentante) {
      const ciFile = this.documentoRepresentante as Blob;
      data.append("documento_representante", ciFile);
    } else {
      const ciFile = new File(["file"], "documento_representante") as Blob;
      data.append("documento_representante", ciFile);
    }
    if (this.documentoLogo) {
      const ciFile = this.documentoLogo as Blob;
      data.append("documento_logo", ciFile);
    } else {
      const ciFile = new File(["file"], "documento_logo") as Blob;
      data.append("documento_logo", ciFile);
    }

    for (const prop in this.dataForm) {
      if (this.dataForm.hasOwnProperty(prop)) {
        // @ts-ignore
        if (this.dataForm[prop] || this.dataForm[prop] === 0) {
          // @ts-ignore
          data.append(prop, this.dataForm[prop]);
        }
      }
    }

    this.$axios
      .post("solicitud/convenio", data, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(result => {
        this.$q.loading.hide();
        this.$swal({
          icon: "success",
          title: this.$t("notifications.titles.solicitud").toString(),
          text: this.$t("notifications.success.application").toString(),
          confirmButtonText: this.$t("buttons.accept").toString(),
          didDestroy: () => {
            this.$router.go(0);
          }
        });
      })
      .catch(error => {
        this.$q.loading.hide();
        this.enviarDisable = false;
        const message = error.response.data.message;
        if (message === "FILEA_ERROR") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.solicitud").toString(),
            text: this.$t("notifications.error.FILEA_ERROR").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "FILEC_ERROR") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.solicitud").toString(),
            text: this.$t("notifications.error.FILEC_ERROR").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.solicitud").toString(),
            text: this.$t("notifications.error.ERROR_FORM").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        }
      });
  }
  private cancelarDocumento(documento: string) {
    if (documento === "documentoSii") {
      this.downloadDocumentoSii = false;
      this.documentoSii = null;
    } else if (documento === "documentoPatente") {
      this.downloadDocumentoPatente = false;
      this.documentoPatente = null;
    } else if (documento === "documentoSanitaria") {
      this.downloadDocumentoSanitaria = false;
      this.documentoSanitaria = null;
    } else if (documento === "documentoRepresentante") {
      this.downloadDocumentoRepresentante = false;
      this.documentoRepresentante = null;
    } else if (documento === "documentoLogo") {
      this.downloadDocumentoLogo = false;
      this.documentoLogo = null;
    }
  }
  private mostrarTerminos(footer) {
    this.terminosFooter = footer;
    this.bTerminos = true;
  }
  private onCloseTerminos(info) {
    if (info.footer == true) {
      if (info.response == true) {
        this.modelTerminos = "si";
        this.bTerminos = false;
      } else if (info.response == false) {
        this.modelTerminos = "no";
        this.bTerminos = false;
      } else {
        this.bTerminos = false;
      }
    } else {
      this.bTerminos = false;
    }
  }
  private rutSanitize(rut: string) {
    rut.replace(/\./g, "").replace(/-/g, "");
    const rutNumber = rut.substring(0, rut.length - 1);
    const dv = rut.substring(rut.length - 1);
    return `${rutNumber}-${dv}`;
  }
  private checkFile(target: HTMLInputElement) {
    let flag = false;
    const extensions = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
    if (target.files && target.files[0]) {
      for (const x in extensions) {
        if (target.files[0].type.includes(extensions[x])) {
          flag = true;
        }
      }
      if (target.files[0].size > 5 * 1024 * 1024 || !flag) {
        if (target.name === "documentoSii") {
          this.invalidFileSii = true;
        } else if (target.name === "documentoPatente") {
          this.invalidFilePatente = true;
        } else if (target.name === "documentoSanitaria") {
          this.invalidFileSanitaria = true;
        } else if (target.name === "documentoRepresentante") {
          this.invalidFileRepresentante = true;
        } else if (target.name === "documentoLogo") {
          this.invalidFileLogo = true;
        }
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.solicitud").toString(),
          text: this.$t("notifications.error.ARCHIVO_MAXIMO").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
      } else if (target.files[0].size <= 0 || !flag) {
        if (target.name === "documentoSii") {
          this.invalidFileSii = true;
        } else if (target.name === "documentoPatente") {
          this.invalidFilePatente = true;
        } else if (target.name === "documentoSanitaria") {
          this.invalidFileSanitaria = true;
        } else if (target.name === "documentoRepresentante") {
          this.invalidFileRepresentante = true;
        } else if (target.name === "documentoLogo") {
          this.invalidFileLogo = true;
        }
      } else {
        if (target.name === "documentoSii") {
          this.documentoSii = target.files[0];
          this.invalidFileSii = false;
        } else if (target.name === "documentoPatente") {
          this.documentoPatente = target.files[0];
          this.invalidFilePatente = false;
        } else if (target.name === "documentoSanitaria") {
          this.documentoSanitaria = target.files[0];
          this.invalidFileSanitaria = false;
        } else if (target.name === "documentoRepresentante") {
          this.documentoRepresentante = target.files[0];
          this.invalidFileRepresentante = false;
        } else if (target.name === "documentoLogo") {
          this.documentoLogo = target.files[0];
          this.invalidFileLogo = false;
        }
      }
    }
  }
  public validateRut(newValue: string | null) {
    if (!newValue) {
      return false;
    }
    var texto = newValue;
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    var dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
  private validEmail(email) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  private base64ImageToBlob(str) {
    // extract content type and base64 payload from original string
    var pos = str.indexOf(";base64,");
    var type = str.substring(5, pos);
    var b64 = str.substr(pos + 8);

    // decode base64
    var imageContent = atob(b64);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    var buffer = new ArrayBuffer(imageContent.length);
    var view = new Uint8Array(buffer);

    // fill the view, using the decoded base64
    for (var n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }

    // convert ArrayBuffer to Blob
    var blob = new Blob([buffer], { type: type });

    return blob;
  }
}
