






import { Component, Vue } from "vue-property-decorator";
import LoginComponent from "@/components/auth/LoginComponent.vue";

@Component({
  components: {
    LoginComponent
  },
  meta: {
    // sets document title
    title: "Login - Tarjeta Punta Arenas",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Login Tarjeta Punta ArenasS."
      },
      keywords: { name: "keywords", content: "Tarjeta Punta Arenas" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class LoginView extends Vue {}
