import { render, staticRenderFns } from "./ValidateAccount.vue?vue&type=template&id=7fc2779a&scoped=true&"
import script from "./ValidateAccount.vue?vue&type=script&lang=ts&"
export * from "./ValidateAccount.vue?vue&type=script&lang=ts&"
import style0 from "./ValidateAccount.vue?vue&type=style&index=0&id=7fc2779a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fc2779a",
  null
  
)

export default component.exports